
// Theme imports
$custom: #5fbeaa;
$lightdark4: #444444;
$lightdark7: #797979;
$width: 100%;
$lightgray: #7a8c9a;

// Martin's new
$workspace-col-bg: #36404a;


.workspace-view {

  .navbar,
  .subnav {
    overflow: hidden;
  }

  .content-page {
    overflow: hidden;
  }

  .righthand-wrapper {
    float: right;
     width: calc(100% - 240px);
  }

  .container-body {
    margin: 0 auto;   
    overflow: hidden;
    justify-content: center;
  }
}

.side-menu {
  bottom: 0;
  top: 0;
  width: 240px;
  z-index: 2;
}

.side-menu.left {
  background: $workspace-col-bg;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  height: $height;
  margin-top: 0;
  margin-bottom: -70px;
  padding-bottom: 70px;
  position: fixed;
  overflow-y: scroll;
}


/*===================================
   DARK: Topbar,Left-sidebar,Right-sidebar
  ===================================*/

/* Notification */
.notification-list {
  margin-left: 0 !important;

  .noti-title {
    margin-bottom: 0;
    width: auto;
    padding: 12px 20px;

    h5 {
      margin: 0;
    }

    .label {
      float: right;
    }
  }

  .noti-icon {
    font-size: 18px;
    padding: 0 12px;
    vertical-align: middle;
    color: $white;
  }
  .noti-icon-badge {
    display: inline-block;
    position: absolute;
    top: 14px;
    right: 8px;
  }
  .notify-item {
    padding: 10px 20px;

    .notify-icon {
      float: left;
      height: 36px;
      width: 36px;
      line-height: 36px;
      text-align: center;
      margin-right: 10px;
      border-radius: 50%;
      color: $white;

      img {
        margin-top: 4px;
      }
    }
    .notify-details {
      margin-bottom: 0;
      overflow: hidden;
      margin-left: 45px;
      text-overflow: ellipsis;
      white-space: nowrap;
      b {
        font-weight: 500;
      }
      small {
        display: block;
      }
      span {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 13px;
      }
    }
  }
  .notify-all {
    border-radius: 0 0 0.25rem 0.25rem;
    margin: 0 0 -5px 0;
    background-color: lighten($dark,10%);
  }
}

.sidebar-inner {
  height: $height;
}

#sidebar-menu, #sidebar-menu ul, #sidebar-menu li, #sidebar-menu a {
  border: 0;
  font-weight: normal;
  line-height: 1;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  text-decoration: none;
}

#sidebar-menu {
  padding-bottom: 30px;
  padding-top: 30px;
  width: 100%;
  .nav > li > a {

    .badge {
      position: absolute;
      right: 10px;
      top: 12px;
    }
  }

  a {
    line-height: 1.3;
  }
  ul {
    ul {
      display: none;
      li {
        border-top: 0;
      }
      li.active {
        a {
          color: $custom;
        }
      }
      a {
        color: rgba($white,0.4);
        display: block;
        padding: 10px 20px 10px 65px;

        &:hover {
          color: rgba($white,0.7);
        }
        i {
          margin-right: 5px;
        }
      }
      ul {
        a {
          padding-left: 80px;
        }
      }
    }
  }
  .label {
    margin-top: 2px;
  }
  .subdrop {
    border-left: 3px solid $custom;
    color: $custom !important;
  }
}

#sidebar-menu > ul > li > a {
  color: rgba($white,0.5);
  display: block;
  padding: 12px 20px;
  margin: 4px 0;
  border-left: 3px solid transparent;
  &:hover {
    color: $white;
    text-decoration: none;
  }
}

#sidebar-menu > ul > li > a > span {
  vertical-align: middle;
}

#sidebar-menu {
  ul {
    li {
        -webkit-transition: all .58s;
        -o-transition: all .8s;
        transition: all .8s ease;
      .menu-arrow {
        -webkit-transition: all .15s;
        -o-transition: all .15s;
        transition: all .15s ease;
        position: absolute;
        right: 20px;
        display: inline-block;
        text-rendering: auto;
        line-height: 20px;
        font-size: 14px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

      }
      &:hover {
        border-left: 3px solid $gray-medium-light;
        a .menu-arrow {
        right: 17px;
        }
      }

      a{
        i{
          display: inline-block;
          font-size: 16px;
          line-height: 17px;
          margin-left: 3px;
          margin-right: 15px;
          text-align: center;
          vertical-align: middle;
          width: 20px;
        }
        i.md {
          font-size: 18px;
        }
      }
    }
  }
}


#sidebar-menu > ul > li > a > i.i-right {
  float: right;
  margin: 3px 0 0 0;
}

#sidebar-menu > ul > li > a.active {
  border-left: 3px solid $custom;
  background-color: $custom;
  color: #fff !important;
}


.menu-title {
  padding: 40px 20px 10px 20px !important;
  letter-spacing: .035em;
  pointer-events: none;
  cursor: default;
  font-size: 13px;
}


/* Small Menu */
.side-menu-sm {
  width: 180px;
  text-align: center;
  #sidebar-menu>ul>li>a>i {
    display: block;
    font-size: 18px;
    line-height: 24px;
    width: 100%;
    margin: 0;
  }
  #sidebar-menu ul ul a {
    padding: 10px 20px 10px 20px;
  }
}

.side-menu-sm + .content-page {
  .footer {
    left: 180px;
  }
}
#wrapper.enlarged .side-menu-sm {
  text-align: left;
}
#wrapper.enlarged .side-menu-sm #sidebar-menu ul li a i {
  display: inline-block;
  font-size: 18px;
  line-height: 17px;
  margin-left: 3px;
  margin-right: 15px;
  vertical-align: middle;
  width: 20px;
}

.side-menu-sm + .content-page {
  margin-left: 180px;
}

/* Header 2 */
.page-header-2 {
  background: lighten($dark,5%);
  margin: -20px -20px 22px -20px;
  padding: 10px 20px 3px 20px;
}



#wrapper.enlarged {
  .navbar-custom {
    margin-left: 70px;
  }
  .menu-title ,.menu-arrow{
    display: none !important;
  }
  #sidebar-menu {
    ul {
      ul {
        margin-top: -5px;
        padding-top: 5px;
        z-index: 9999;
        background-color: darken($dark,5%);
      }
    }
  }
  .left.side-menu {
    width: 70px;
    z-index: 5;

    #sidebar-menu > ul > li > a {
      padding: 15px 20px;
      min-height: 52px;

      &:hover {
        background-color: darken($dark,5%) !important;
      }
      &:active {
        background-color: darken($dark,5%) !important;
      }
      &:focus {
        background-color: darken($dark,5%) !important;
      }

      i {
        margin-right: 20px !important;
        font-size: 20px;
      }
    }
    .label {
      position: absolute;
      top: 5px;
      left: 35px;
      text-indent: 0;
      display: block !important;
      padding: .2em .6em .3em !important;
    }
    #sidebar-menu {
      ul > li {
        position: relative;
        white-space: nowrap;
        &:hover > a {
          position: relative;
          width: 260px;
          background: darken($dark,5%);
          color: $custom;
          border-color: $gray-medium-light;
        }
        &:hover > ul {
          display: block;
          left: 70px;
          position: absolute;
          width: 190px;
          a {
            background: darken($dark,5%);
            box-shadow: none;
            padding-left: 15px;
            position: relative;
            width: 186px;
            z-index: 6;

            &:hover {
              color: rgba($white,0.7);
            }
          }
        }
        &:hover {
          a {
            span {
              display: inline;
            }
          }
        }
      }
      li{
        .show-menu + ul{
          display: block;
          left: 70px;
          position: absolute;
          width: 190px;
          a {
            background: darken($dark,5%);
            box-shadow: none;
            padding-left: 15px;
            position: relative;
            width: 186px;
            z-index: 6;

            &:hover {
              color: rgba($white,0.7);
            }
          }

        }
      }
      a.subdrop {
        color: $custom !important;
      }
      ul > li > ul {
        display: none;
      }
      ul {
        ul {
          li {
            &:hover > ul {
              display: block;
              left: 190px;
              margin-top: -36px;
              position: absolute;
              width: 190px;
            }
          }
          li > a {
            span.pull-right {
              -ms-transform: rotate(270deg);
              -webkit-transform: rotate(270deg);
              position: absolute;
              right: 20px;
              top: 12px;
              transform: rotate(270deg);
            }
          }
          li.active {
            a{
              color: $custom;
            }
          }
        }
      }
      ul > li > a {
        span {
          display: none;
          padding-left: 10px;
        }
      }
    }
    .user-details {
      display: none;
    }
  }
  .content-page {
    margin-left: 70px;
  }
  .footer {
    left: 70px;
  }
  .topbar {
    .topbar-left {
      width: 70px !important;
      .logo {
        span {
          display: none;
          opacity: 0;
        }
        .icon-c-logo {
          display: block;
          line-height: 70px;
        }
      }
    }

  }
  #sidebar-menu > ul > li {
    &:hover > a.open {
      :after {
        display: none;
      }
    }
    &:hover > a.active {
      :after {
        display: none;
      }
    }
  }

  .tips-box {
    display: none;
  }
}


