form#workspaceForm {

  div[dbs-reference-number] {
    &.application-in-progress {
      label.spin {
        display: flex;
      }
    }

    label.spin {
      align-items: center;
      color: #00A000;
      display: none;
      font-size: 12px;
      font-weight: bold;
      height: 100%;
      margin: 0;
      position: absolute;
      z-index: 999;

      i.fa {
        animation: spin 1.5s linear infinite;
        font-size: 16px;
        margin: 0;
        padding: 0 10px;
      }

      @keyframes spin {
        from {
          transform: rotate(0);
        }
        to {
          transform: rotate(360deg);
        }
      }
    }

    input[type="text"][name="criminal__candidate[workspaceDbs]"] {

      &:disabled {
        background: inherit;
      }

      &+span.input-group-btn {
        .btn {
          border-radius: 0 3.5px 3.5px 0;
          font-size: 12px;
          height: auto;
          line-height: 1;
          margin-top: 0;
          padding: 0;

          &:disabled {

          }

          a {
            color: inherit;
            padding: 0.7rem 1.2rem;

            &:hover {
              text-decoration: none;
            }
            &:active {
              background: rgb(138, 155, 162);
            }
          }
        }
      }
    }
  }
}

// PCS-434

.circle.dbs-reference-number {
  border: 4px solid #A5638F !important;
}
td.row-status.dbs-reference-number .icon-status::before {
  border: 4px solid #A5638F !important;
}
.workspace-drawer.in-progress.dbs-reference-number {
  border: 2px solid #A5638F !important;
}