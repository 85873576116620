.alert-box {
  .alert-box-title {
    border: 2px solid $alertBoxTitleBorder;
    font-size: 30px;
    font-weight: 400;
    padding: 28px 45px;
    margin: 0;
    color: $alertBoxTitle;
    @include media-breakpoint-down(sm) {
      font-size: 22px;
    }
  }
  .alert-box-content {
    color: $alertBoxContentColor;
    background: $alertBoxContentBg;
    padding: 17px 45px 25px;
    font-size: 14px;
    line-height: 26px;
  }
}
