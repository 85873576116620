.total-board {
    margin-top: 4%;
    margin-bottom: 4%;
    background: $gray-light;
    text-align: center;

    @include media-breakpoint-down(md) {
        margin-top:6rem;
    }
}
.total-number {
    font-size: 34px;
    font-weight: 700;
    color: $gray-dark;
    border-radius: 50%;
    width: 90px;
    height: 90px;
    padding: 16px 0;
    text-align: center;
    margin: 0 auto;
}

.tn-1 {
    border: 3px solid $color-not-submitted;
}

.tn-2 {
    border: 3px solid $color-in-progress;
}

.tn-3 {
    border: 3px solid $color-verified-clear;
}

.total-description {
    color: $gray-medium-light;
    font-weight: 500;
    letter-spacing: 1px;
    padding-top: 10px;
    transition: all 0.5s ease;
}

.total-item {
    padding: 4rem 7rem;

    @include media-breakpoint-down(sm) {
        padding: 1rem;
    }
    @include media-breakpoint-down(md) {
        padding: 1rem;
    }
}

a.total-item-link {
    text-decoration: none;
}

a.total-item-link:hover {
    .total-description {
        color: $gray-dark;  
    }          
}

.box-header {
    font-size: $font-size*2;
    color: #54646E;
    margin-bottom: 4rem;
}
.box-logo {
    text-shadow: -1px 1px 1px rgba(150, 150, 150, 1);
    h1 {
        font-size: $font-size * 2.5;
        color: #2b318a;
    }
    span {
        color: #e9131d;
    }
}
.box {
    margin-top: 40%;
}
.grey-box {
    margin-top: 5%;
    background-color: $gray-light;
    padding: 2rem;
    border-radius: 3px;
}
.record-box-title {
    text-transform: uppercase;
    color: $gray-medium;
    font-size: $font-size * 0.8;
    font-weight: 700;
    letter-spacing: 1px;
}
.record-box-data {
    color: $gray-dark;
    padding-top:2px;
}

.download-list {
    list-style: none;
    -webkit-padding-start: 0;
    margin-bottom: 0;
}
.white-bg {
    background-color: white;
    padding: 20px;
}
.grey-box .white-bg p {
    padding: 1rem;
    margin-bottom: 0;
}
.demo-box {
    bottom: 0;
    right: 0;
}