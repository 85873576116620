@mixin border-radius($radius) {
  border-radius: $radius;
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
}
.progress.progress-gradient {
  > .progress-bar {
    @include border-radius(0);
    width: 12.5%;
    &:first-of-type {
      @include border-radius(.2rem 0 0 .2rem);
    }
    &:last-of-type {
      @include border-radius(0 .2rem .2rem 0);
    }
  }
  @for $i from 1 through 8 {
    .progress-#{$i} {
      background-color: rgba(44,49,138, $i * 0.1 + 0.2);
    }
    &.progress-steps-#{$i} {
      .progress-bar {
        width: 100% / $i;
      }
    }
    + button + nav .progress-label {
      &.progress-steps-#{$i} {
        .step-text {
          width: 100% / $i;
        }
      }
    }
  }
  @media(max-width: 767px) {
    @for $i from 1 through 8 {
      + button + nav .progress-label {
        &.progress-steps-#{$i} {
          .step-text {
            width: 100%;
          }
        }
      }
    }
  }
}