td {
  &.verified-clear,
  &.verified-alert,
  &.in-progress {
    .icon-status {
      margin-top: 7px;
      margin-right: .5rem;
      color: #2acd76;
      display: inline-block;
      font: normal normal normal #{$fa-font-size-base}/#{$fa-line-height-base} FontAwesome;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      &:before {
        content: $fa-var-check;
      }
    }
  }
  &.verified-alert {
    .icon-status {
      color: #ff7800;
      &:before {
        content: $fa-var-exclamation-triangle;
      }
    }
  }
  &.in-progress {
    .icon-status {
      color: #8a9ba2;
      &:before {
        content: $fa-var-circle-o;
      }
    }
  }
}