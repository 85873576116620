$font-size : 14px;
$primary-font-family: 'Roboto', sans-serif;
$secondary-font-family: 'Lato', sans-serif;

$blue-dark: #222C3C;
$gray-dark: #54646E;
$gray-medium: #8A9BA2;
$gray-medium-light: #AABAC1;
$gray-light-blue: #EFF3F6;
$gray-light: #F9FBFC;

$blue: #303EC0;
$red: #F01653;

$color-not-submitted: #E0E3E7;
$color-in-progress: #8A9BA2;
$color-verified-clear: #2ACD76;
$color-verified-alert: #FF7800;
$color-completed: #3292FF;

$height: 100%;

html {
    font-size: $font-size;
}
body {
    font-family: $primary-font-family;
    color: $gray-dark;
    @include media-breakpoint-down(sm) {
        body {
            font-size: $font-size * 0.6;
        }
    }
    @include media-breakpoint-down(md) {
        body {
            font-size: $font-size * 0.8;
        }
    }
}
h1, h2, h3, h4, h5, h6 {
    font-family: $primary-font-family;
}

h2 {
    font-weight: 400;
    padding-top: 20px;
}
mark {
    background-color: yellow;
    padding: 0;
}

//.workspace-view .container {
//     z-index: -1;
//}

.workspace-view.backoffice .container {
    z-index: auto;
}

.workspace-view {
    .container.bleed-container {
        z-index: 2;
    }
}

.container-body {
    min-height: 85vh;
}
.container-body.col-lg-3.col-md-5.col-xs-10 {
    min-height: 60vh;
}
a {
    transition: color 0.3s linear;
   -webkit-transition: color 0.3s linear;
   -moz-transition: color 0.3s linear;
    &:hover {
        color: #456c9c;
    }
}
.a-underlined {
    text-decoration: underline!important;
}
.mt-10 {
    margin-top: 10%;
}
.mt-6 {
    margin-top:4rem!important;
}

.btn {
    font-weight: 500;
    border-radius: 2rem;
    padding: .7rem 1.2rem;
    border: none;
    -webkit-appearance: inherit;
    background-color: $blue;
    line-height: 1.8rem;
    background: linear-gradient(to right, darken($blue, 15%) 50%, $blue 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: all .25s ease-in;
    cursor: pointer;
    color: white !important;

    &:hover {
        background-position: left bottom;
        color: white;
    }
}

.proceed-btn {
    width: 100%;
    background-color: $blue;
    color: white;

    &:hover {
        background-color: darken($blue, 15%);
        color: white;
    }
    &:active {
        background-color: darken($blue, 15%);
    }
    &:disabled {
        background-color: #E1E2E4;
        color: #686A6C;
    }
}

.cancel-btn {
    background: linear-gradient(to right, darken($red, 10%) 50%, $red 50%);
    background-size: 200% 100%;
    background-position: right bottom;
}

.tertiary-btn {
    background: linear-gradient(to right, darken($gray-medium, 10%) 50%, $gray-medium 50%);
    background-size: 200% 100%;
    background-position: right bottom;
}

.complete-btn {
    width: 100%;
    background: linear-gradient(to right, darken($color-verified-clear, 10%) 50%, $color-verified-clear 50%);
    background-size: 200% 100%;
    background-position: right bottom;
}

.complete-inactive-btn {
    width: 100%;
    background: $gray-medium;
    cursor: default;
    background-size: 200% 100%;
    background-position: right bottom;
}

.complete-btn-wrapper {
    margin: 0 auto;
}

.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: block;

    .upload-btn {
        color: white;
        border: none;
        background-color: $gray-medium;
        padding: 10px 20px;
        border-radius: 5px;
        font-weight: bold;
        transition: all 0.25s ease;
        font-weight: 500;
    }

    &:hover {
        .upload-btn {
            cursor: pointer;
            background-color: darken($gray-medium, 10%);
        }
    }
}

.upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}


.bleed-container {
    .bleed-btn {
        position: absolute;
        border-radius: 100px;
        color: white;
        right: 1rem;
        top: -1.4rem;
        padding: .7rem 1.8rem;
         z-index: 2;

        &:nth-child(2) {
            right: 13rem;
            @include media-breakpoint-down(sm) {
                right: 10.2rem;
            }
        }
        @include media-breakpoint-down(sm) {
            position: relavite;
            right: 1.2rem;
            top: 1rem;
        }
    }

    .bleed-btn.add-candidate-btn {
        top: -4rem;
    }

}



.sticky-btn-wrapper {
    position: absolute;
    right: 40px;
    top: 160px;
    transition: all 0.25s ease;
    z-index: 0;
}

#sticky-btn {
    padding: .7rem 1.8rem;
    transition: all 0.25s ease;
}

#sticky {
    display: none;
    right: 1rem;
    top: -1.4rem;
    padding: .7rem 1.8rem;
    z-index: 0 !important;
    transition: all 0.25s ease;
}

.user-icon {
    font-size: $font-size * 4;
    padding: 0px 12px 0px 12px;
    flex: 1 0;
}

.user-info {
    flex: 11 0;
}

.circle {
    margin-right: 0.7rem;
    margin-top: 0.28rem;
    -moz-border-radius: 1rem/1rem;
    -webkit-border-radius: 1rem 1rem;
    border-radius: 1rem/1rem;
    width: 1rem;
    height: 1rem;
    display: inline-flex;
}
.square {
    margin-right: 0.7rem;
    width: 1rem;
    height: .5rem;
    vertical-align: middle;
    display: inline-flex;

    &.grey-border {
        border-color:#E0E3E7;
    }
}
.tooltip.show {
    opacity: 1;
}
.tooltip.bs-tooltip-top .tooltip-inner, .tooltip.bs-tooltip-left .tooltip-inner, .tooltip.bs-tooltip-right .tooltip-inner, .tooltip.bs-tooltip-bottom .tooltip-inner{
    background-color: $tooltipBg;
    color: $tooltipColor;
    text-align: left;
}
.tooltip.bs-tooltip-top .arrow::before, .tooltip.bs-tooltip-auto[x-placement^="top"] .arrow::before {
    border-top-color: $tooltipBg;
    text-align: left;
}
.tooltip.bs-tooltip-left .arrow::before, .tooltip.bs-tooltip-auto[x-placement^="left"] .arrow::before {
    border-left-color: $tooltipBg;
}
.tooltip.bs-tooltip-bottom .arrow::before, .tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    border-bottom-color: $tooltipBg;
}
.tooltip.bs-tooltip-right .arrow::before, .tooltip.bs-tooltip-auto[x-placement^="right"] .arrow::before {
    border-right-color: $tooltipBg;
}
.form-heading {
    margin-top: 7%;
    margin-bottom: 5%;
}
.btn-circle {
    width: 30px;
    height: 30px;
    padding: 6px 0px;
    border-radius: 15px;
    text-align: center;
    font-size: $font-size * 0.9;
    line-height: 1.42857;
}
.btn-circle.btn-xl {
    width: 70px;
    height: 70px;
    padding: 10px 16px;
    border-radius: 35px;
    font-size: $font-size * 2.1;
    line-height: 1.33;
}
.form-tooltip-info {
    right: 7.5%;
    top: 7px;
    color: $colorScheme;
    @media(max-width: 767px) {
        right: 20px;
    }
}
.error {
    padding: 0 10px;
    color: $red;
}


/* PROGRESS BAR */

.progress-bar-container {
    margin-top: 5%;
}

.progress {
    background-color: $gray-light-blue;
}

.progress-bar {
    background-color: $color-verified-clear;
    border-radius: .2rem;
}

.step-text {
    width: 12.5%;
    padding: .5rem;
    font-size: $font-size * 0.9;
    color: $gray-medium;

    @include media-breakpoint-down(sm) {
        width: 100%;
    }

    &.font-weight-bold {
        color: #43515B;
    }
}

.progress-label {
    padding: 0;
}

.step-progress-toggle {
    color: $gray-dark;
    background-color: transparent;
    border: 0;
    &[aria-expanded=true] {
        .step-progress-toggle-icon {
            transform: rotate(180deg);
            top: 4px;
        }
    }
}

.step-progress-toggle-icon {
    position: relative;
    right: -10px;
    top: 2px;
    transition: transform 0.2s, top 0.2s;
}

.step-progress-nav {
    &.collapse {
        @include media-breakpoint-up(md) {
            display: block;
        }
    }
}

.client-logo {
    width: 200px;
}
.client-grade-group {
    margin: 2rem;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    div{
        &:not(.position-absolute){
            background-color: white;
            padding: 1.2rem;
            width: 33%;
        }
        &.active {
            background-color: transparent;
            border: 2px solid transparent;
            &:before, &:after {
                content: "\f058";
                position: absolute;
                left: 50%;
                top: -16px;
                font: normal normal normal 14px/1 FontAwesome;
                font-size: 30.8px;
                color: transparent;
                -webkit-transform: translate(-50%,0);
                -moz-transform: translate(-50%,0);
                -ms-transform: translate(-50%,0);
                -o-transform: translate(-50%,0);
                transform: translate(-50%,0);
            }
            &:before {
                content: "";
                width: 22px;
                height: 22px;
                background: #ffffff;
                top: -13px;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                border-radius: 50%;
            }
        }
        &:first-child {
            border-top-left-radius: 3rem;
            border-bottom-left-radius: 3rem;
            color: #E9005A;
            &.active {
                background-color: #ffe6f0;
                border-color: #E9005A;
                &:before, &:after {
                    color: #E9005A;
                }
            }
        }
        &:nth-child(2) {
            color: #FC6208;
            &.active {
                background-color: #ffdecb;
                border-color: #FC6208;
                &:before, &:after {
                    color: #FC6208;
                }
            }
        }
        &:last-child {
            color: #2AC663;
            border-top-right-radius: 3rem;
            border-bottom-right-radius: 3rem;
            &.active {
                background-color: #E3FDEF;
                border-color: #2AC663;
                &:before, &:after {
                    color: #2AC663;
                }
            }
        }
        .fa {
            right: 43%;
            top: -26%;
            border: none;
            background: white;
            font-size: $font-size*2.2;
        }
    }

}
.badge-progress {
    background-color: $color-in-progress;
    color: white;
}
.badge-not-submitted {
    background-color: $color-not-submitted;
    color: $gray-medium;
}

.badge-success {
    background-color: $color-completed;
}

.download-list a {
    color: $gray-dark;
    text-decoration: underline;
    &:hover {
        color: $blue;
    }
}

.badge {
    padding: 0.5rem 0.8rem;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
}

.header-name {
    font-weight: 800;
    color: $gray-dark;
}

.header-company {
    color: $gray-medium;
    font-size: $font-size * 0.9;
    padding-top: 3px;
}

.header-user-icon {
    padding: 10px 0px;
    img {
        max-height: 88px;
        max-width: 150px;
        width: auto;
    }
}


/* CANDIDATE FORM */

.candidate-form {
    .grey-box {
        margin-top: 3%;

        h2 {
            margin-bottom: 0;
            font-size: 24px;
        }
    }
    > div {
        > .grey-box {
            margin-top: 0;
        }
    }

    .btn {
        margin-top: 0;
    }
    .button-container {
            display: flex;
            justify-content: flex-end;
            .form-group {
                margin-left: 5px;
            }
        }

    textarea {
        width: 100%;
    }
}

/* WELCOME */

.helpful-tip {
    background-color: lighten($color-verified-clear, 47%);
    padding: 20px 40px;
    text-align: center;
    margin: 30px auto;

    .heading {
        font-weight: 700;
        color: $color-verified-clear;
        padding-bottom: 5px;
    }
}


/* TEMPORARY FOOTER LINK */

.temp {
    background-color: $gray-light-blue;
    text-align: center;
    padding: 20px;
    margin: 0 auto;
    width: 100%;
    display: block;
}

.alert {
    margin: 1rem 0;
}
.hidden {
    display: none;
}
.canvasItem {
    cursor: crosshair;
    border: 1px solid lightgray;
    background-color: white;
    max-width: 100%;
}

.canvasItem.canvasError {border-color: $red;}

.uploadSubjectFile {
    .eduSubj {
        max-height: 33px;

        .eduSubjSpan1 {padding: 6px 5px;}
        .eduSubjSpan2 {
            padding: 0 5px;
            line-height: 33px;
        }
    }
}

.submitCandidate {cursor: pointer;}
.submitWorkspace {z-index: 100;}

.form-group .field-error,
.form-group .radiobox .field-error {border-color: $red;}

.barCharts { flex: 1; padding: 20px; border-right: 1px solid #EBEFF1 }

.pieCharts { flex: 1; padding: 20px; }

.singleBarChartLabel,
.titleCheckType,
.singleBarChartValue,
.titleAverage {

}

.singleBarChartLabel,
.titleCheckType {float: left;}

.singleBarChartValue,
.titleAverage {float: right;}

.singleBarChartLabel {height: 23px; margin: 20px 0px 5px 0px};
.singleBarChartValue {font-size: 24px;}

.singleBarChart {margin: 0px; padding: 0px; }

.chartContainer {
    background-color: #F8FBFC;
    border: 1px solid #EBEFF1;
    border-top: 3px solid #00CD7C;
    display: flex;
}

.clr {clear: both}

.pieLegend table tr td {padding: 5px;}

.pieLegend table tr td .colorMini {float: left; width: 10px; height: 10px;}

.pieLegend table tr td.pieValue {font-weight: bold;}

.grey-box .col-6 textarea {width: 100%;}
textarea {padding: 10px;}

.consentStatic h3 {font-size: 28px; margin-top: 10px; margin-bottom: 10px;}
.consentStatic h4 {font-size: 18px; margin-top: 10px; margin-bottom: 10px;}
.consentStatic p {margin-top: 20px; margin-bottom: 20px;}

.agencySeparator {margin-top: 30px; margin-bottom: 30px;}

.educationSeparator {margin-top: 30px; margin-bottom: 30px;}

._agreement ._label {font-weight: normal;}

.new-label {color: $blue;}

.progress-bar-container #step-progress-nav .stepWithErrors {color: $red;}

.errorsTopMessage {margin-top: 5px; color: $red;}

.gradingCommentArea {background-color: white; padding: 25px; margin: 25px;}

.gradingCommentArea a {color: #54646e; text-decoration: underline;}

.consent-dbs {
    .contacts {
        justify-content: flex-start;
    }
}

.criminal-dbs-message {
    margin-top: -20px;
    .alert-box {
        .alert-box-content {
            padding: 17px 45px 18px;
        }
    }
}

.workspace-drawer[aria-controls="criminal"] {
    margin-bottom: 0;
}

.agreeError {border: 1px solid $red; padding: 10px;}
.canvasError {border: 1px solid $red;}

.removeUser {color: $red;}

.removeSavedCollectionItem {color: $red; cursor: pointer;}

.numericShort {width: 70px;}