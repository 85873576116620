$breakpoint-alpha: 840px;

table {
    font-size: $font-size;
}
.checks-table {
    margin-top: 10px;

    tr {
        &:hover {
            cursor: pointer;
            td {
                background-color: white;
                border-top: 1px solid #E6EDF2;
                border-bottom: 1px solid #E6EDF2;
              }
        }
    }
    th {
      padding: 1rem;
      font-size: $font-size*0.8;
      font-weight: 700;
      color:$gray-medium;
      letter-spacing: 1px;
        &:last-child {
          &:after {
              content: "";
          }
        }
        @include media-breakpoint-down(md) {
            display: none;
        }
    }
    td {
      padding: 1rem;
      background-color: $gray-light;
      border-top: 1px solid $gray-light-blue;
      border-bottom: 1px solid $gray-light-blue;
      transition: all .4s ease;

      @include media-breakpoint-down(md) {
        display: block;
      }

      &:before {
        content: attr(data-th)":\00a0 \00a0 ";
        font-weight: bold;
        font-size: $font-size;
        @include media-breakpoint-up(md) {
            display: none;
          }
      }
    }
}
[data-th="SUBMITTED DATE"] {
    color:#788991;
    
    div {
        color: #54646E;
    }
}
.pink {
    color:#F0166D;
}
.checks-table tr td:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    @include media-breakpoint-down(md) {
        border-left: none;
        border-top: 3px solid;
        border-top-right-radius: 4px;
    }
  }
  .checks-table tr td:last-child {
    border-right: 1px solid #E6EAEE;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
        &:after {
            font-size: $font-size * 2;
            content: "›";

        }
        @include media-breakpoint-down(md) {
            display: none;
        }
}
.yellow-border {
    border: solid 4px #FF7800;
}
.green-border {
    border: solid 4px #2ACD76;
}
.grey-border {
    border: solid 4px #BDBFC3;
}
.table-description {
    color: $gray-medium;
    padding: 1rem;
}
.table-tools input[type=search] {
    padding: 1rem;
    margin-top: 0.7rem;
    height: 36px;
    width: 210px;
}

.table-tools input[type=search]::placeholder {
        color: $gray-medium-light;
    }

.table-tools select {
    margin-left: 2rem;
}
.search {
     position: relative;
}

.search .fa-search { 
  position: absolute;
  top: 1.4rem;
  right: 1rem;
  color: $gray-medium-light;
}
.table-tools .form-control {
    margin-left: 1rem;
}
.data-name {
    font-weight: bold;
    font-size: $font-size*1.1;
    color:#43515B;
    @include media-breakpoint-down(md) {
        font-weight: normal;
    }
}
.table-seperator {
    height: 0.5rem;
    background-color: white;
}
.not-submitted-border {
    border-left: 5px solid #E0E3E7;
    border-top-color: #E0E3E7;
}
.in-progress-border {
    border-left: 5px solid #8A9BA2;
    border-top-color: #8A9BA2;
}
.verified-alert-border {
    border-left: 5px solid #FF7800;
    border-top-color: #FF7800;
}
.verified-clear-border {
    border-left: 5px solid #2ACD76;
    border-top-color: #2ACD76;
}
.completed-border {
    border-left: 5px solid $color-completed;
    border-top-color: $color-completed;
}

.progress-data .circle {
    margin-right: 1rem;
}
.progress-data .square {
    margin-right: 1rem;
}

.not-submitted-border,
.in-progress-border,
.verified-alert-border,
.verified-clear-border,
.completed-border,
.data-email,
.data-date-count,
.data-status {
    color: $gray-medium;
}

.record-table {

    table-layout: fixed;
    width: 100%;

    thead {
        tr {
            text-transform: uppercase;
        }
        th {
            font-weight: 700;
            color: $gray-medium;
            font-size: $font-size*0.8;
            padding-bottom: 1rem;
            letter-spacing: 1px;
        }
    }

    .row-check {
        width: 50%;
    }

    .row-status {
        width: 20%;
    }

    .row-comments {
        width: 30%;
    }

    td {
        border-top: 1px solid #E0E3E7;
  
        padding-bottom: 1.5rem;
        padding-top: 1.5rem;
        padding-right: 1rem;
        vertical-align: top;
    }
    .fa {
        margin-top: .3rem;
        margin-right: .3rem;
        color: #2ACD76;
    }
    .circle {
        margin-top: .28rem;
        margin-right: .7rem;
    }

    .square {
        margin-top: .48rem;
        margin-right: .7rem;
    }

    label {
        padding-top: 5px;

        span {
            color: $gray-medium-light;
        }
    }
    .table.table-inside {
        td {
            border-top: 0;
            padding: 0;
            label {
                padding: 0;
                margin: 0;
            }
        }
    }
    &.results-table {
        td.in-progress .icon-status:before {
            content: '';
            display: inline-block;
            width: 14px;
            height: 14px;
            border: 4px solid #bdbfc3;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
        }
    }
}

.table-striped {
  tbody tr:nth-of-type(odd) {
    background-color: $gray-light;
  }
}

.record-zebra-table {


    thead {
        text-transform: uppercase;

        th {
            border: none;
            padding: 1rem 0;
        }
    }

    .table-heading-small {

        th{
            padding-left: 1rem;
            font-size: 12px;
            font-weight: 700;
            color: $gray-medium;
            border-bottom: 1px solid $gray-light-blue;
        }
    }

    tbody {

        td {
            width: 33%;
            padding: 1rem;
            border: none;
        }
        th {
            width: 33%;
            padding: 1rem;
            border: none;
        }
        
    }
}

.record-zebra-table.auto-cols-table-zebra tbody td,
.record-zebra-table.auto-cols-table-zebra tbody th {
    width: auto;
}

.directorships-table {
    tbody {
        th, td{
            width: 25%;
        }
        
    }
}

.results-table {

    .row-check {
        width: 40%;
    }

    .row-status {
        width: 30%;
    }

    .row-comments {
        width: 30%;
    }

    td {
        line-height: 2.2rem;
    }

    .fa {
        margin-top: 0.7rem;
        margin-right: 0.5rem;
    }

    .fa-download {
        color: $gray-medium;
    }
}



/** PEOPLE CHECK WORKSPACE SPECIFIC **/

.workspace-drawer {
    background-color: $gray-light;
    padding: 1.5rem;
    margin: 1rem 0;
    transition: all 0.25s ease;

    h4, p {
        margin-bottom: 0;
        line-height: 2rem;
    }

    &:hover {
        cursor: pointer;
    }

    .d-flex .fa {
        margin-top: 0.5rem;
        margin-right: 0.5rem;
    }

    &.verified-clear {
        border: 2px solid lighten($color-verified-clear, 10%);

        .d-flex .fa {
            color: $color-verified-clear;       
        }
         &:hover {
            border: 2px solid $color-verified-clear;
        }
    }

    &.in-progress {
        border: 2px solid lighten($color-in-progress, 10%);

        .d-flex .fa {
            color: $color-in-progress;       
        }
         &:hover {
            border: 2px solid $color-in-progress;
        }
    }

    &.verified-alert {
        border: 2px solid lighten($color-verified-alert, 10%);

        .d-flex .fa {
            color: $color-verified-alert;       
        }
         &:hover {
            border: 2px solid $color-verified-alert;
        }
    }

    .fa-angle-down {
        font-size: 2rem;
        transition: all 0.25s ease;
    }

    &.up {
        .fa-angle-down {
            transform: rotate(180deg);
        }
        
    }

}

.workspace-table {
    padding: 20px 0; 
    table-layout: fixed;
    width: 100%;

    thead {
        tr {
            text-transform: uppercase;
        }
        th {
            font-weight: 700;
            color: $gray-medium;
            font-size: $font-size*0.8;
            padding: 30px 30px 20px 15px;
            letter-spacing: 1px;
        }

        th div {
            padding-bottom: 10px;
            border-bottom: 1px solid $gray-light-blue;
        }
    }

    h4 {
        text-align: left;
        padding-top: 30px;
        font-weight: 400;
    }

    th,
    td {
        padding: 5px 15px;

        &:first-child {
            background-color: $gray-light;
            text-align: left;
            font-weight: 700;
            width: 30%;
            padding-left: 40px;
        }

        &:nth-child(2) {
            background-color: $gray-light;
            margin-right: 10px;
        }

        &:nth-child(3) {
            padding-left: 40px;
        }

        h5 {
            color: $gray-medium;
            text-align: left;
            padding-top: 20px;
        }
           
    }

    input[type=radio] {
        margin-left: 0;
    }

    td.form-input-half {
        display: flex;
        flex-wrap: wrap;

        input {
            width: 49%;
        }

        input:first-child {
            margin-right: 1%;
        }

        input:last-child {
            margin-left: 1%;
        }
    }
}

.global-check-notes,
.workspace-table {

    textarea {
        height: 100px;
    }

     .form-group {
        margin-top: 1rem;

        .col-form-label {
            text-transform: uppercase;
            font-weight: 700;
            color: $gray-medium;
            font-size: $font-size*0.8;
            letter-spacing: 1px;
        }
    }

}

.noChecks {text-align: center;}
