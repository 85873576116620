.navbar {
    background: $gray-light-blue;
    padding: 1rem 2rem;
}
.nav {
    background: $gray-light-blue;
    padding: 5px 0;
    height: 49px;
}
.nav {
    a {
        color: $gray-medium-light;
        &.active {
            color: $gray-dark;
        }
        &:hover {

        }
        @include media-breakpoint-down(sm) {
            font-size: $font-size;
        }
    }
}
.subnav {
    font-size: $font-size * 1.1;
    background: $gray-light-blue;
   

    li {
        padding: 0px 20px 0px 0px;
    }
}
.navbar-header {
    color: $gray-medium;
}

.nav-link {
    font-weight: 500;
}

 .navbar-brand {
    padding-top: 1rem;
     img {
         max-width: 100%;
     }
 }

.header-email {
    padding: 3px 0;
}