.login-input {
    border: none;
    background-color: #f4f4f4;
    padding: 0.6rem 1rem;
}

.table-tools {
	select {
		margin-top: 1rem;
	}
	.form-group.search {
		.button-search {
			background: transparent;
			border: 0;
			padding: 11px;
			position: absolute;
			right: 0;
			top: 0;
			cursor: pointer;
			outline: none;
			transition: color 0.3s linear;
			-webkit-transition: color 0.3s linear;
			-moz-transition: color 0.3s linear;
			&:hover, &:focus {
				color: #456c9c;
			}
		}
	}
}

.grey-box .col-form-label {
    text-align: left;
    font-weight: bold;
    padding-top: 5px;
	padding-bottom: 5px;
	line-height: 1.7;
	@media(max-width: 767px) {
		padding-right: 45px;
	}
}

.form-control::-webkit-input-placeholder { color: #83949C; }  /* WebKit, Blink, Edge */
.form-control:-moz-placeholder { color: #83949C; }  /* Mozilla Firefox 4 to 18 */
.form-control::-moz-placeholder { color: #83949C; }  /* Mozilla Firefox 19+ */
.form-control:-ms-input-placeholder { color: #83949C; }  /* Internet Explorer 10-11 */
.form-control::-ms-input-placeholder { color: #83949C; }  /* Microsoft Edge */

.form-control {
	line-height: 1;
	height: 33.5px;
}
.form-group {

	input {
		&::placeholder {
			padding: 5px;
			color: $gray-medium-light;
		}
	}

	.custom-select {
		height: 43px;

	}

	select:disabled { 
		color: $gray-medium-light; 
	}

}

form {
	.btn {
		margin-top: 40px;
		height: 46px;
	}
}

._agreement {
	.webkit {
		display: -webkit-box;

		._checkbox {
			padding: 0;

			._input {
				padding: 0;
				position: relative;
				top: 1px;
			}
		}

		._label {
			padding: 0 6px;
		}
	}
}

@media(max-width: 767px) {
	.education-group {
		div + div {
			margin-top: 10px;
		}
	}
}