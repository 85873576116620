.dashboard-chart {
  h4 {
    font-weight: 400;
    font-size: 20px;
    margin-bottom: 50px;
  }
  .titleCheckType,
  .titleAverage {
    font-size: 12px;
    letter-spacing: 1px;
  }
  .singleBarChartLabel {
    font-weight: bold;
  }
  .pieWrapper {
    display: flex;
    align-items: center;
  }
  .pieLegend table tr {
    + tr {
      border-top: 1px solid rgba(0,0,0,.1);
    }
    td {
      padding-top: 8px;
      padding-bottom: 8px;
      .colorMini {
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
      }
      &.pieValue {
        text-align: right;
      }
    }
  }
  hr.no-bottom {
    margin-bottom: 0;
  }
  .singleBarChartValue {
    margin-top: 10px;
  }
}