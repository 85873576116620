.right-side-buttons {
  display: block;
  width: 100%;
  margin: 20px 0;
  .button-item {
    display: inline-block;
    + .button-item {
      margin-left: 15px;
    }
  }
  @media(max-width: 480px) {
    .button-item {
      display: block;
      .btn {
        display: block;
        width: 100%;
      }
      + .button-item {
        margin-left: 0;
        margin-top: 10px;
      }
    }
  }
}