footer {
    margin-top: 80px;
    width: 100%;
    background-color: $blue-dark;
    color: $gray-medium-light;
    text-align: center;
    
    @include media-breakpoint-down(sm) {
        font-size: $font-size;
    }

    .footer-notice {
        margin: 0 auto;
        padding-top: 3rem;
    }
    .copyright {
        margin-bottom: 2rem;
    }       
}

.contacts {
    font-weight: bold;
    margin-top: 3%;
    justify-content: center;

    @include media-breakpoint-down(sm) {
        font-size: $font-size*0.8;
    }

    div {
        padding: 0 20px;
    }

    &.agree-block {
        div._checkbox div {
            padding: 0;
        }
    }
}