.radiobox {
  display: inline-block;
  position: relative;
  margin-top: 11px;
  input[type=radio]{
    visibility: hidden;
    display: none;
  }
  label {
    display: block;
    position: relative;
    padding: 0 0 0 25px;
    margin: 0;
    z-index: 9;
    cursor: pointer;
    -webkit-transition: all 0.45s linear;
    font-weight: 400;
    color: #83949C;
  }
  .check{
    display: block;
    position: absolute;
    border: 1px solid #83949C;
    border-radius: 100%;
    height: 15px;
    width: 15px;
    top: 1px;
    left: 0;
  }
  input[type=radio]:checked ~ .check .inside {
    display: block;
    position: relative;
    background: $blue;
    border-radius: 100%;
    height: 100%;
    width: 100%;
    top: 0;
    margin: auto;
    border: 2px solid #fff;
  }
  + .radiobox {
    margin-left: 20px;
  }
}