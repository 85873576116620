.page-settings {
  h1 {
    margin-top: 30px;
  }
  h2 {
    margin-bottom: 30px;
  }
  h3 {
    margin-bottom: 30px;
    padding-top: 15px;
    &.with-desc {
      margin-bottom: 10px;
      + p {
        margin-bottom: 30px;
      }
    }
  }

  td {
    vertical-align: middle;
  }
  th {
    font-weight: 400;
  }

  .role-label,
  .last-logged-in {
    color: $gray-medium
  }

  .form-new-company {
    [type=submit] {
      margin-top: 20px;
    }
  }

  .table-label {
    color: #74858D;
    font-family: Roboto, sans-serif;
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  hr.subtitle-dashed {
    border-style: dashed;
  }

  .table-users {
    table-layout: fixed;
    th {
      border: 0;
    }

    td,
    th {
      text-align: center;
    }

    td {
      border: 1px solid #E0E3E7;
      background-color: #fafafa;

      &:first-child {
        background-color: transparent;
        border-left: 0;
        text-align: left;
      }

      &:last-child {
        border-right: 0;
      }
    }

    tr {
      &:last-child {
        td {
          border-bottom: 0;
        }
      }
    }
  }

  .table-companies {
    table-layout: fixed;
    .company-name,
    .company-users-count {
      text-align: left;
    }
    .company-name {
      font-size: 1.5rem;
      display: block;
    }

    .company-users-count {
      color: $gray-medium-light;
      font-style: italic;
    }
    td, th {
      &:first-child {
        width: 95px;
        img {
          width: 75px;
        }
      }
    }
    th {
      button.btn {
        margin-top: 0;
        height: 35px;
        line-height: 1rem;
        i {
          padding-left: 5px;
        }
      }
    }
    td {
      .action-icon + .action-icon {
        //padding-left: 10px;
      }
    }
  }
  .custom-checkbox {
    input[type="checkbox"]{
      display: none;
    }
    input[type=checkbox] + label {
      position: relative;
      top: -7px;
      left: -10px;
      cursor: pointer;
      &:before {
        content: '';
        width: 20px;
        height: 20px;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        background: #ffffff;
        border: 1px solid #979797;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
      }
      &:after {
        content: "";
        width: 12px;
        height: 7px;
        position: absolute;
        top: 5px;
        left: 4px;
        border-bottom: 3px solid transparent;
        border-left: 3px solid transparent;
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }
    }

    input[type=checkbox]:checked + label:after {
      border-bottom-color: #2acd76;
      border-left-color: #2acd76;
    }
    input[type=checkbox].maintenance-checkbox + label {
      padding-left: 25px;
      left: 0;
    }
  }

  @keyframes spinner {
    to {transform: rotate(360deg);}
  }

  .spinner {
    label {
      visibility: hidden;
    }
    .spinner-wrapper {
      position: relative;
      &:before {
        content: '';
        box-sizing: border-box;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 20px;
        height: 20px;
        margin-top: -20px;
        margin-left: -10px;
        border-radius: 50%;
        border: 1px solid #ccc;
        border-top-color: #07d;
        animation: spinner .6s linear infinite;
      }
    }
  }
  .flex-block {
    display: flex;
    align-items: center;
  }

  form .checkboxContainer {
    float:left; width: 200px; height: 50px;
  }

  form .checkboxLabel {
    padding-left: 30px;
  }
}
