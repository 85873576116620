.inputfile {
  overflow: hidden;
  position: relative;
  display: flex;
  padding: 0;
  margin: 0;
  cursor: pointer;
  height: 33.5px;
  > span {
    padding: .375rem .75rem;
    display: inline-block;
    line-height: 1.5;
    + span {
      border-left: 1px solid #ced4da;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 220px;
    }
  }
  [type=file] {
    display: none;
  }
}
.inputfile-wrapper {
  position: relative;
  .remove-icon {
    position: absolute;
    width: 33px;
    height: 33px;
    display: block;
    top: 0;
    right: 0;
    background: #e2e2e2;
    border-radius: .25rem;
    &:before {
      content: "\f00d";
      display: block;
      width: 17px;
      height: 17px;
      position: absolute;
      top: 50%;
      left: 10px;
      font-family: FontAwesome;
      font-weight: normal;
      font-style: normal;
      text-decoration: inherit;
      -webkit-font-smoothing: antialiased;
      font-size: 16px;
      line-height: 1;
      -webkit-transform: translate(0,-50%);
      -moz-transform: translate(0,-50%);
      -ms-transform: translate(0,-50%);
      -o-transform: translate(0,-50%);
      transform: translate(0,-50%);
    }
  }
}
